@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl text-transparent bg-clip-text bg-gradient-to-br from-primary to-green-500 dark:from-secondary dark:to-green-500;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-2xl;
  }
}

@layer components {
  .imply_hyperlink {
    @apply font-bold border-transparent transition-all duration-400 border-b-4 hover:border-accent focus:border-accent;
  }
}
